export function Navigation() {
  // TOUCH: open menu onclick
  const burger = document.querySelector(".burger");
  const nav = document.querySelector("#main-menu");
  burger.addEventListener("click", function () {
    burger.classList.toggle("is-active");
    nav.classList.toggle("is-active");
  });


  const menuHasDropdown = [...document.querySelectorAll("#main-menu > .has-dropdown")];
  const navbarItems = [...document.querySelectorAll("#main-menu > .navbar-item")];
  const isCurrent = document.getElementsByClassName('is-current')[0];

  const mobileLink = document.querySelectorAll(".open-mobile-accordion")

  // if (window.innerWidth <= 1024) {
  //   mobileLink.forEach((link) => {
  //     link.addEventListener("click", function () {
  //       link.nextElementSibling.click();
  //     })
  //   })
  // }


  // toggle menu on click
  if (window.innerWidth <= 1024) {
    menuHasDropdown.forEach((current, i) => {
      current.addEventListener("click", function (e) {
        var collapsibleArea = current.getElementsByClassName('navbar-dropdown')[0];
        var toggleButton = current.getElementsByClassName('is-toggle')[0];
        // e.preventDefault();
        if (!current.classList.contains("is-open")) {
          collapsibleArea.style.maxHeight = collapsibleArea.scrollHeight + "px";
          current.classList.add("is-open");
          toggleButton.classList.add('is-active');
        } else if (current.classList.contains("is-open")) {
          collapsibleArea.style.maxHeight = null;
          current.classList.remove("is-open");
          toggleButton.classList.remove('is-active');
        }

        let restMenuDropdowns = menuHasDropdown.slice();
        restMenuDropdowns.splice(i, 1);

        restMenuDropdowns.map(menuDropdown => {
          const innerCollapsible = menuDropdown.getElementsByClassName('navbar-dropdown')[0];
          const innerButton = menuDropdown.getElementsByClassName('is-toggle')[0];

          menuDropdown.classList.remove('is-open');
          innerCollapsible.style.maxHeight = null;
          innerButton.classList.remove('is-active');
        })
      })
    })
  } else {
    menuHasDropdown.forEach((current, i) => {

      current.addEventListener("mouseover", function (e) {
        current.classList.add("is-hovered");

        let restOptions = navbarItems.slice();
        restOptions.splice(i + 1, 1);

        restOptions.map(option => {
          option.classList.remove('is-active');
          option.classList.remove('is-hovered');
        })

      });
      current.addEventListener("mouseout", function (e) {
        current.classList.remove("is-hovered");
        isCurrent.classList.add("is-active");
      })
    })
  }


  window.onresize = function () {
    if (window.innerWidth >= 1024) {
      burger.classList.remove("is-active");
      nav.classList.remove("is-active");
    }
  };
};